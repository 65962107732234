<template>
    <div class="personCard">
        <div v-show="!loading">
            <div class="personCard-bg"></div>
            <div>
                <div ref="printMe" class="personCard-printMe">
                    <div class="personCard-printMe-user">
                        <img :src="card.avatar" style="border-radius:50%;">
                        <div>
                            <h1>{{card.name}}</h1>
                            <!-- <div>{{card.corp_name}}</div> -->
                        </div>
                    </div>
                    <img class="personCard-printMe-img" :src="card.qr_code">
                    <div>长按识别二维码图案, 加我企业微信。</div>
                </div>
            </div>
        </div>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import upload from '@/utils/upload'
    import { wxWorkSend } from '@/common/wx'
    import Loadings from '@/components/loading'
    import { Empty, Tag, Overlay, Col, Row, Toast, ActionSheet} from 'vant'
    export default {
        name:"namecard",
        data() {
            return {
                show: true,
                loading: false,
                finished: false,
                searchLoading: false,
                showOverlay: false,
                output:'',
                base64_avatar:'',
                base64_qrcode:'',
                user_info:{},
                tagList:[],
                showUser:false,
                showInvite:false,
                external_userid:'',
                btnNum:1,
                state:'',
                total:{},
                card:{},
                listFinishedText: '没有更多了',
            }
        },
        computed: {},
        components: {
            [Tag.name]:Tag,
            [Col.name]:Col,
            [Row.name]:Row,
            [Empty.name]:Empty,
            [Overlay.name]:Overlay,
            [ActionSheet.name]:ActionSheet,
            loadings:Loadings,
        },
        created () {
            this.getCard()
        },
        mounted(){
            
        },
        methods: {
            getCard(){
                let query = this.$route.query
                this.loading = true
                this.$api.namecard_index({qywx_id:query.qywx_id})
                .then(res=>{
                    this.card= res.data.namecard
                    this.loading=false
                })
            },
        },
        watch: {},
    }
</script>

<style lang="less" scoped>
    .personCard{
        min-height: 100vh;
        background: #fff;
        &-bg{
            //background: #f8f8f8;
            height: 8px;
        }
        &-nav{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
            .van-button--mini{
                font-size: 14px;
                margin-right: 10px;
            }
            &-tag{
                margin: 5px 5px 5px 0;
            }
        }
        &-title{
            display: flex;
            align-items: center;
            position: relative;
            padding: 10px 0;
            h1{
                font-size: 20px;
                margin-left: 10px;
            }
            &-Refresh{
                display: flex;
                align-items: center;
                position: absolute;
                right: 10px;
                color: #00cca2;
                span{
                    margin-left: 5px;
                }
            }

        }
        &-printMe{
            width: 90%;
            background: #fff;
            margin: 0 auto;
            border-radius: 15px;
            box-shadow: 0 0 1px #f2f2f2;
            text-align: center;
            padding-bottom: 25px;
            margin-top:30px;
            border: 1px solid rgba(0, 0, 0, 0.07);
            &-user{
                display: flex;
                align-items: center;
                padding: 25px 0 0 25px;
                img{
                    margin-left:20px;
                    width: 56px;
                    height: 56px;
                    border-radius: 2px;
                    margin-right: 15px; 
                }
                h1{
                    font-size: 22px;
                    margin-bottom: 5px;
                }
            }
            &-img{
                width: 260px;
                height: 260px;
                margin: 15px 0;
            }
        }
        &-btn{
            padding: 0 20px;
            margin: 15px 0;
        }
        &-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        &-block{
            width: 85%;
            height: 350px;
            padding: 10px;
            background: #fff;
            border-radius: 4px;
            h1{
                font-size: 16px;
                text-align: center;
                padding: 5px 0;
            }
        }
        &-content{
            padding: 0 15px;
            &-num{
                display: flex;
                align-items: center;
                margin: 10px 0;
                span{
                    font-size: 12px;
                }
            }
            &-btn{
                margin-top: 10px;
                .van-button{
                    margin-right: 10px;
                }
            }
            &-item{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img{
                    width: 46px;
                    height: 46px;
                    border-radius: 2px;
                    margin-right: 10px;
                }
            }
            &-list{
                height: 300px;
                overflow-y: scroll;
            }
        }
    }
</style>